import { BillbeeOrderState } from "./Order";
import { ReturnItemDb, ReturnItemEmail } from "./ReturnItem";

export enum SupportCaseStatus {
  Canceled = "CANCELED", // order is canceled
  Editing = "EDITING", // client is editing
  Submitting = "SUBMITTING", // client is submitting
  Validating = "VALIDATING", // customer care checks photos and data
  Rejected = "REJECTED", // customer care has rejected the case
  Shipping = "SHIPPING", // customer care has approved shipping, email to customer was sent and waiting for returned items
  Completed = "COMPLETED", // client has received his return, case closed
  Corrected = "CORRECTED", // customer care has corrected the case
}

export enum SupportCaseCreatedWith {
  serviceportal = "serviceportal",
  serviceportalAdmin = "serviceportalAdmin",
  serviceportalAdminUpdate = "serviceportalAdminUpdate",
  amazonFbmAutoResponder = "amazonFbmAutoResponder",
  amazonFbaReport = "amazonFbaReport",
  admin = "admin", // fallback
}

export interface SupportCaseBase {
  country: string;
  ordernumber: string;
  orderState: BillbeeOrderState;
  invoiceDate?: Date; // might not have been set yet, eg early orders
  complaintDate?: string | Date;
  status: SupportCaseStatus;
  returnItems: ReturnItemDb[];
  customerMessage?: string;
  agreedTerms: string;
  shopId: number;
  createdWith: SupportCaseCreatedWith;
  mails: {
    isSentToClient: boolean;
    isSentToCustomerCare: boolean;
  };
}

export type SupportCaseDb = SupportCaseBase;

export interface SupportCaseFromApi extends Omit<SupportCaseBase, "invoiceDate" | "mails" | "shopId" | "createdWith"> {
  invoiceDate: Date;
}

export interface SupportCaseBackendWip extends Omit<SupportCaseBase, "invoiceDate" | "returnItems"> {
  invoiceDate: Date;
  email: string;
  name: {
    first: string;
    last: string;
  };
  returnItems: ReturnItemDb[];
}

export interface SupportCaseEmail
  extends Omit<SupportCaseBase, "returnItems" | "invoiceDate" | "mails" | "shopId" | "createdWith"> {
  email: string;
  name: {
    first: string;
    last: string;
  };
  invoiceDate: Date;
  returnItems: ReturnItemEmail[];
}
