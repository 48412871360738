import { createContext, useContext, useReducer } from "react";
import { authReducer, AuthState, AuthStateDispatch } from "../../model/AuthState";
import { localStore } from "../localStore";

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<AuthStateDispatch | undefined>(undefined);

function initAppState(): AuthState {
  if (typeof window === "undefined") {
    return { authStatus: "validating" };
  }
  const authStatus = localStore.getAuthToken() === undefined ? "anonymous" : "validating";
  return { authStatus };
}

export function AuthProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [state, dispatch] = useReducer(authReducer, undefined, initAppState);
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export function useAuthState(): AuthState {
  const context = useContext(AuthStateContext);
  if (context === undefined) throw new Error("useAuthState must be used within an AuthProvider");
  return context;
}

export function useAuthDispatch(): AuthStateDispatch {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) throw new Error("useAuthDispatch must be used within an AuthProvider");
  return context;
}
