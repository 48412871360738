import { AppProps } from "next/app";
import { Router } from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../frontend/contexts/AuthContext";
import "../frontend/styles/globals.css";
import { initTrackersContextAware } from "../utils/helper";
const queryClient = new QueryClient();

// Sentry and LogRocket Init
initTrackersContextAware();

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function Serviceportal({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <>
          {/* ? Move it to head Or will this be sufficient in body? */}
          <Script src="/polyfill-ios-smoothscroll.js"></Script>
          <Component {...pageProps} />
        </>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default Serviceportal;
