import { OrderItem } from "./OrderItem";

export enum BillbeeOrderState {
  Bestellt = "BESTELLT", // 1  // showStornoScreen
  Bestaetigt = "BESTAETIGT", // 2 // showStornoScreen
  Zahlung_erhalten = "ZAHLUNG_ERHALTEN", // 3 // showStornoScreen
  Versendet = "VERSENDET", // 4 //
  Reklamation = "REKLAMATION", // 5 //
  Geloescht = "GELOESCHT", // 6 //
  Abgeschlossen = "ABGESCHLOSSEN", // 7 //
  Storniert = "STORNIERT", // 8 //
  Archiviert = "ARCHIVIERT", // 9 //
  Rated0 = "RATED0", // 10 //
  Mahnung = "MAHNUNG", // 11 //
  Mahnung2 = "MAHNUNG2", // 12 //
  Gepackt = "GEPACKT", // 13 //
  Angeboten = "ANGEBOTEN", // 14 //
  Zahlungserinnerung = "ZAHLUNGSERINNERUNG", // 15 //
  Im_Fulfillment = "IM_FULFILLMENT", // 16 //
}

export interface Order {
  ordernumber: string;
  state: BillbeeOrderState;
  shopId: number;
  country: string;
  emailhash: string;
  orderDate: string;
  shippingDate: string;
  modifiedDate: string;
  invoiceDate: Date;
  orderItems: OrderItem[];
}
