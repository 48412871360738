const environmentVariables = {
  IS_APP_ENV_PRODUCTION: Boolean(process.env.NEXT_PUBLIC_APP_ENV === "production"),
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_VERSION: process.env.NEXT_PUBLIC_VERSION,
  NEXT_PUBLIC_NAME: process.env.NEXT_PUBLIC_NAME,
  NEXT_PUBLIC_COMMIT_HASH: process.env.NEXT_PUBLIC_COMMIT_HASH,
};

const envsReady: Record<string, string | boolean> = {};
Object.entries(environmentVariables).forEach(([key, value]) => {
  if (value === undefined) {
    throw new Error(`env var: ${key} not set`);
  } else {
    envsReady[key] = value;
  }
});

export const IS_APP_ENV_PRODUCTION = envsReady.IS_APP_ENV_PRODUCTION as boolean;
export const NEXT_PUBLIC_APP_ENV = envsReady.NEXT_PUBLIC_APP_ENV as "production" | "development" | "test";
export const NEXT_PUBLIC_VERSION = envsReady.NEXT_PUBLIC_VERSION as string;
export const NEXT_PUBLIC_NAME = envsReady.NEXT_PUBLIC_NAME as string;
export const NEXT_PUBLIC_COMMIT_HASH = envsReady.NEXT_PUBLIC_COMMIT_HASH as string;
