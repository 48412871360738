import { LoginResponse } from "./LoginResponse";

export interface Unauthenticated {
  authStatus: "anonymous" | "validating";
}

export interface Authenticated {
  authStatus: "authenticated";
  authToken: string;
  user: LoginResponse;
}

export type AuthState = Unauthenticated | Authenticated;
export type AuthStateAction = { type: "logout" } | { type: "login"; authToken: string; user: LoginResponse };
export type AuthStateDispatch = (action: AuthStateAction) => void;

export function authReducer(state: AuthState, action: AuthStateAction): AuthState {
  switch (action.type) {
    case "logout":
      return {
        authStatus: "anonymous",
      };
    case "login":
      return {
        authStatus: "authenticated",
        authToken: action.authToken,
        user: action.user,
      };
    default: {
      throw new Error("Undhandled auth action");
    }
  }
}
